.foods {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.05);
}

.foods > h2 {
  text-align: center;
  font-size: 4rem;
  margin: 6rem 0;
}

.container {
  display: flex;
  flex-flow: column nowrap;
}

.food {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.food > img {
  width: 100%;
  height: auto;
}

.food > h2 {
  font-size: 2.5rem;
  margin: 1.5rem 0;
  text-align: center;
  color: #000;
}

.food > p {
  font-size: 2rem;
  padding: 0 2rem;
  text-align: justify;
  color: rgba(0, 0, 0, 0.85);
}

.food > p:last-of-type {
  font-size: 2.5rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 1);
  margin: 0 0 5rem 0;
  margin-top: auto;
}

.menu {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  margin: 2rem 0 15rem;
}

.foods h3 {
  text-align: center;
  font-size: 3rem;
}

.menuContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

@media all and (min-width: 768px) {
  .container {
    flex-flow: row wrap;
    justify-content: center;
    gap: 1rem;
  }

  .food {
    width: 45%;
  }

  .food > img {
    width: 100%;
    height: auto;
    border-radius: 20px;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .menuContainer {
    margin: 0 1rem;
  }
}

@media all and (min-width: 1024px) {
  .container {
    gap: 4rem;
  }
}

@media (pointer: fine) and (hover: hover) {
  .food img:hover {
    transform: scale(1.05);
  }
}
