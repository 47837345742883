.container {
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub1 {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.sub1 > h2 {
    text-align: center;
    font-size: 4rem;
}

.sub1 > h2:after {
    content: '';
    display: block;
    width: 20rem;
    height: 2px;
    background-color: #3867d6;
    margin: 4rem auto;
}

.sub1 > p {
    font-size: 2rem;
    word-spacing: 1px;
    letter-spacing: 1px;
    width: 80%;
    margin-bottom: 5rem;
}

.sub2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    flex: 1 0 auto;
}

.card:nth-child(2) {
    margin-left: -5rem;
}

.card h3 {
    font-size: 2.5rem;
    margin: 2rem 0;
}

.card p {
    font-size: 2rem;
    text-align: justify;
}

.card svg {
    color: #fed330;
    margin-bottom: 2rem;
    font-size: 5rem;
}

.card:first-of-type svg {
    color: #fd9644;
}

.card:last-of-type svg {
    color: #26de81;
}

@media all and (max-width: 1024px) {
    .sub1 > h2 {
        display: flex;
        flex-direction: column;
    }

    .sub2 {
        flex-wrap: wrap;
    }

    .card p {
        text-align: center;
    }

    .card:nth-child(2) {
        margin-left: 0;
    }
}

@media all and (max-width: 425px) {
    .sub2 {
        justify-content: center;
    }
}