.opening {
    height: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
}

.opening > h2 {
    font-size: 4rem;
    text-align: center;
}

.times {
    margin-top: 3rem;
    display: grid;
    grid-template-rows: 1fr;
}

.times > p {
    font-size: 2rem;
    margin: 1rem 0;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.times > p > span {
    justify-self: start;
}

.times > p > span:first-of-type {
    margin-right: 5rem;
    justify-self: end;
}