.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.heading {
    font-family: 'Roboto', sans-serif;
}

.subHeading {
    font-size: 4rem;
}

.wrapper > p {
    font-size: 2rem;
    line-height: 3.1rem;
}

.wrapper > button {
    width: 30%;
    height: 60px;
    border-radius: 10px;
    margin-top: 2rem;
    font-size: 3rem;
    background-color: seagreen;
    color: white;
    transition: transform .2s;
}

.wrapper > button:hover {
    cursor: pointer;
    transform: scale(1.1);
}

@media (max-width: 425px) {
    .wrapper > button {
        width: 70%;
    }
}

@media (max-width: 320px) {
    .wrapper > .subHeading {
        margin-top: 7rem;
    }
}
