.gallery {
  background-color: #0000000d;
  margin: 0;
  padding: 5rem 0 10rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 3rem;
}

.heading {
  text-align: center;
  font-size: 4rem;
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
}

.container div {
  display: flex;
  justify-content: center;
}

.container img {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 1rem;
}

@media all and (min-width: 1024px) {
  .container div {
    display: block;
    flex-basis: 30%;
    overflow: hidden;
  }

  .container img {
    width: 100%;
    object-fit: unset;
  }
}
