.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.2)), url("../../images/hero.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero > h1,
.hero > p {
  color: white;
  text-align: center;
}

.hero > h1 {
  margin-bottom: 2rem;
  letter-spacing: 4px;
}

.hero > p {
  font-size: 3rem;
  text-transform: uppercase;
  word-spacing: 3rem;
  letter-spacing: 3px;
}

@media all and (max-width: 320px) {
  .hero > h1 {
    font-size: 8rem;
  }
}
