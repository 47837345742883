footer {
  width: 100%;
  height: 40vh;
  background-color: #333;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.subContainer {
  display: flex;
  flex-flow: row wrap;
  width: 200px;
  justify-content: center;
}

.imprint {
  color: white;
  font-size: 1.5rem;
}

.rights {
  color: white;
  font-size: 1.5rem;
  word-spacing: 2px;
  text-align: center;
  line-height: 3rem;
}

@media (hover: hover) and (pointer: fine) {
  .imprint:hover {
    cursor: pointer;
  }
}
